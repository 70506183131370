<template>
  <div class="view-echarge">
    <div class="bankbox">
      <img
        src="../../assets/images/new/back-icon.png"
        alt=""
        @click="$router.back()"
      />
      <div class="bankname">钱包充值</div>
    </div>
    <div class="container-box-wrap">
      <div class="container-box">
        <div class="container-box1-header">
          <div
            @click="changePayTypeOnClick(item)"
            v-for="(item, index) in global_info?.pay_channel"
            :key="index"
            class="pay-item"
            :class="{
              'pay-item-active': activePayTypeItem.name === item.name,
            }"
          >
            <div class="text">{{ item.name }}</div>

            <div class="img-box">
              <img
                :src="icons"
                alt=""
                v-for="(icons, index) in item.image"
                :key="index"
              />
            </div>
          </div>
        </div>
        <div class="container-box1-tips">
          <div class="title-box">
            <div class="icon"></div>
            <div class="text">充值说明：</div>
          </div>
          <div class="content-box">
            <div
              v-if="global_info.pay_one_info"
              v-html="global_info.pay_one_info"
              class="content-box-row"
            ></div>
            <div
              v-if="global_info.pay_more_info"
              v-html="global_info.pay_more_info"
              class="content-box-row"
            ></div>
          </div>
        </div>
        <div class="money-input">
          <div class="input-wrap">
            <el-input-number
              :precision="0"
              v-model="moneyInput"
              @change="handleChange"
              :controls="false"
              :min="minInput"
              :max="maxInput"
              :placeholder="`请输入充值数额，金额范围(${minInput}-${maxInput})`"
            ></el-input-number>
          </div>
        </div>
        <div class="main-center">
          <div class="check-box">
            <div style="padding-top: 0.034rem">
              <img
                class="point"
                src="../../assets/images/about/bag/mb-check.png"
                v-if="!status"
                alt=""
                @click="status = true"
              />
              <img
                class="point"
                src="../../assets/images/about/bag/mb-checked.png"
                alt=""
                v-else
                @click="status = false"
              />
            </div>
            <div>
              本人已满18周岁且具备完全行为能力,充值即代表同意《条款和条例》《隐私政策》。盲盒快乐请理性消费,请勿上头
            </div>
          </div>
          <div class="paytext">
            <div class="text1">支付金额（人民币）约：</div>
            <div class="text2">{{ totalPrice }}</div>
          </div>
          <div
            class="tips"
            v-if="global_info.pay_qq"
            v-html="global_info.pay_qq"
          ></div>
          <div class="paybtnbox">
            <div class="paybtn point" @click="dopay">立即支付</div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay @mousewheel.prevent.native="() => {}" :show="payurlshow">
      <div class="paybox" :show="payurlshow">
        <div class="titlebox">
          <p>支付订单</p>
          <img
            class="closeicon point"
            src="../../assets/images/new/bg7.png"
            alt=""
            @click="payClose"
          />
        </div>
        <div class="line"></div>
        <div class="payewmbox">
          <div>
            <div class="ewmbox">
              <img class="payurl" :src="payurl" alt="" />
            </div>
            <div class="typeimg">
              <img
                v-for="(item, index) in activePayTypeItem?.image"
                :src="item"
                :key="index"
              />
            </div>
          </div>
        </div>
        <!-- <div style="text-align: center">
          请截图后打开{{ activePayTypeItem.pay_name }}扫一扫支付
        </div> -->
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Recharge, Info } from "@/network/api.js";
import { mapState, mapMutations } from "vuex";
import QRCode from "qrcode";
export default {
  data() {
    return {
      moneyInput: null,
      status: false,
      activePayTypeItem: {},
      payurlshow: false,
      payurl: "",
      minInput: 5,
      maxInput: 300,
      clicktype: true,
    };
  },
  mounted() {
    this.GetInfo();
  },
  computed: {
    ...mapState(["user", "global_info"]),
    totalPrice() {
      let num = 0;
      num = (this.moneyInput || 0) * 6.5;
      return num;
    },
  },
  watch: {
    activePayTypeItem() {
      if (this.activePayTypeItem?.price_max) {
        this.maxInput = this.activePayTypeItem?.price_max;
      }
      if (this.activePayTypeItem?.price_min) {
        this.maxInput = this.activePayTypeItem?.price_min;
      }
      if (this.moneyInput > this.activePayTypeItem?.price_max) {
        this.moneyInput = this.activePayTypeItem?.price_max;
      }
      if (this.moneyInput < this.activePayTypeItem?.price_min) {
        this.moneyInput = this.activePayTypeItem?.price_min;
      }
    },
  },
  methods: {
    ...mapMutations(["setGlobalInfo"]),
    payClose() {
      this.payurlshow = false;
    },
    GetInfo() {
      this.activePayTypeItem = {};
      Info().then((res) => {
        this.setGlobalInfo(res?.data?.data || {});
        // this.activePayTypeItem = this.global_info?.pay_channel[0];
      });
    },
    dopay() {
      let info = this.user;
      if (!info.true_name) {
        this.$message({
          message: "还未实名认证，请认证后操作",
          type: "error",
        });
        this.$router.push("/about");
      } else {
        if (!this.activePayTypeItem?.type) {
          this.$message({
            message: "请选择顶部的充值方式",
            type: "error",
          });
          return false;
        }
        if (!this.moneyInput) {
          this.$message({
            message: "请输入充值金额",
            type: "error",
          });
          return false;
        }
        if (this.status == false) {
          this.$message({
            message: "请勾选充值协议",
            type: "error",
          });
        } else {
          if (this.clicktype) {
            this.clicktype = false;
            setTimeout(() => {
              this.clicktype = true;
            }, 3000);
            const params = {
              type: this.activePayTypeItem?.type,
              money: this.moneyInput,
            };
            Recharge(params).then((res) => {
              if (res.data.code == 200) {
                this.payurl = res.data.data?.list?.qr_url;
                if (res.data.data?.list?.is_html) {
                  window.open(res.data.data?.list?.content, "_self");
                  return false;
                } else {
                  if (res.data.data?.list?.is_qrcode) {
                    QRCode.toDataURL(this.payurl)
                      .then((url) => {
                        this.payurl = url;
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                  this.payurlshow = true;
                }
              } else {
                this.$message({
                  message: res.data.message,
                  type: "error",
                });
              }
            });
          } else {
            this.$message({
              message: "你点的太快啦",
              type: "error",
            });
          }
        }
      }
    },
    changePayTypeOnClick(item) {
      this.activePayTypeItem = item;
    },
    handleChange() {},
  },
};
</script>

<style lang="scss">
.view-echarge {
  width: 100vw;
  height: 100vh;
  font-size: 0.12rem;
  font-family: PingFang SC, PingFang SC;
  .bankbox {
    width: 3.35rem;
    margin: 0 auto;
    height: 0.44rem;
    display: flex;
    align-items: center;
    background: #020715;
    text-align: center;
    position: relative;
    margin-bottom: 0.1rem;
    img {
      position: absolute;
      width: 0.25rem;
      height: 0.14rem;
    }
    .bankname {
      width: 100%;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.16rem;
      color: #ffffff;
    }
  }
  .container-box-wrap {
    padding: 0 0.2rem;
    .container-box {
      width: 100%;
      min-height: 2rem;
      padding-bottom: 0.3rem;
      border: 0.02rem solid #2f2f5e;
      background: rgba(2, 7, 21, 1);
      .container-box1-header {
        height: 0.58rem;
        background: linear-gradient(135deg, #242448 0%, #42426d 100%);
        display: flex;
        margin-bottom: 0.2rem;
        overflow-x: auto;
        .pay-item {
          width: 25%;
          flex-shrink: 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          justify-content: center;
          .img-box {
            width: 0.22rem;
            height: 0.22rem;
            margin-bottom: 0.06rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.1rem;
            img {
              width: 0.13rem;
              height: 0.13rem;
              margin-right: 0.02rem;
            }
          }
          .text {
            width: 100%;
            font-size: 0.1rem;
            text-align: center;
          }
        }
        .pay-item-active {
          background: url("../../assets/images/about/recharge/bg.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .container-box1-tips {
        padding: 0 0.18rem;
        margin-bottom: 0.3rem;
        .title-box {
          display: flex;
          align-items: center;
          margin-bottom: 0.1rem;
          .icon {
            width: 0.14rem;
            height: 0.14rem;
            background: url("../../assets/images/about/recharge/gantan.png")
              no-repeat;
            background-size: 100% 100%;
            margin-right: 0.06rem;
          }
          .text {
          }
        }
        .content-box {
          .content-box-row {
            color: #8080b5;
            line-height: 0.19rem;
            word-break: break-all;
            span {
              font-size: 0.16rem !important;
            }
          }
        }
      }
      .money-input {
        padding: 0 0.2rem;
        margin-bottom: 0.2rem;
        .input-wrap {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          background: url("../../assets/images/about/recharge/pay-input-bg.png")
            no-repeat;
          background-size: 100% 100%;
          .el-input-number {
            width: 100%;
          }
          .el-input__inner {
            border: none;
            background-color: unset;
            color: #eee;
            font-size: 0.12rem;
            height: 0.32rem;
            line-height: 0.32rem;
            text-align: left;
          }
          .el-input__inner::-webkit-input-placeholder {
            color: rgba(191, 191, 237, 0.5);
          }
        }
      }
      .main-center {
        padding: 0 0.2rem;
        .check-box {
          display: flex;
          margin-bottom: 0.31rem;
          color: #8080b5;
          line-height: 19px;
          img {
            width: 0.12rem;
            height: 0.12rem;
            margin-right: 0.07rem;
          }
        }
        .paytext {
          font-size: 0.12rem;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          margin-bottom: 0.08rem;
          display: flex;
          align-items: center;
          .text2 {
            font-size: 0.14rem;
            font-weight: 500;
            color: #efa900;
          }
        }
        .tips {
          margin-top: 0.08rem;
          font-size: 0.12rem;
          font-weight: 500;
          color: #efa900;
          margin-bottom: 0.2rem;
          span {
            font-size: 0.16rem !important;
          }
        }
        .paybtnbox {
          .paybtn {
            width: 0.86rem;
            height: 0.33rem;
            background: linear-gradient(135deg, #242448 0%, #42426d 100%);
            border: 0.01rem solid #8686db;
            font-size: 0.12rem;
            font-weight: 500;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  .paybox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3.35rem;
    height: 2.6rem;
    background: #09091f;
    border: 0.01rem solid #5d5d9b;
    .titlebox {
      width: 100%;
      height: 0.38rem;
      line-height: 0.38rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 0.16rem;
      color: #ffffff;
      text-align: center;
      position: relative;
      .closeicon {
        width: 0.12rem;
        height: 0.12rem;
        position: absolute;
        right: 0.15rem;
        top: 0.15rem;
      }
    }
    .line {
      width: 100%;
      height: 0;
      border: 0.01rem solid;
      border-image: linear-gradient(
          90deg,
          rgba(93, 93, 155, 0),
          rgba(93, 93, 155, 1),
          rgba(93, 93, 155, 0)
        )
        1 1;
    }
    .payewmbox {
      width: 100%;
      height: 1.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .ewmbox {
        width: 0.98rem;
        height: 0.9rem;
        background: url("../../assets/images/newuser/mb-rechange-payurlbg.png")
          no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .payurl {
          width: 0.77rem;
          height: 0.77rem;
        }
      }
      .typeimg {
        width: 100%;
        height: 0.61rem;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 0.3rem;
          height: 0.3rem;
          margin-right: 0.05rem;
        }
      }
    }
  }
}
</style>
